import React, { useState } from "react"
import { MainLayout } from "../layouts/main.layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import hero from "../assets/img/firstClassHero.png"
import option1 from "../assets/img/firstClassLetters.png"
import option2 from "../assets/img/uspsTrackingIntegration.png"
import option3 from "../assets/img/dynamicTrackingPortal.png"
import option4 from "../assets/img/flexibleMailingOptions.png"
import ABanner from "../components/features/ab.component"

import envelope from "/src/assets/img/envelopSliderIcon.svg"
import letter from "/src/assets/img/letterSliderIcon.svg"
import monitor from "/src/assets/img/monitorSliderIcon.svg"
import reward from "/src/assets/img/rewardSliderIcon.svg"

import dynamic from "/src/assets/img/dynamic.svg"
import competetive from "/src/assets/img/technology.svg"
import service from "/src/assets/img/next-day-service.svg"
import secure from "/src/assets/img/secure.svg"
import legal from "/src/assets/img/legal.svg"
import business from "/src/assets/img/business.svg"
import government from "/src/assets/img/goverment.svg"
import individuals from "/src/assets/img/individuals.svg"

const CertifiedMail = ({ location }) => {
  const [expandedBlock, setExpandedBlock] = useState(1)
  const [activeImage, setActiveImage] = useState(option1)
  const [isFading, setIsFading] = useState(false)

  const blocks = [
    {
      id: 1,
      title: "Envelope Options",
      description: `<div style="display: flex; gap: 10px;">
        <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 1vw; color: #000"><span style="font-weight: 600">Number 10 Envelopes <br /> </span> <span style="font-weight: 300; color: #484848; font-size: 0.8vw; line-height: 1vw;">Ideal for standard letters, holding up to 5 physical pages, including a cover sheet.</span></div>
        <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 1vw; color: #000"><span style="font-weight: 600">9 x 12 Flat Envelopes</span>    <span style="font-weight: 300; color: #484848; font-size: 0.8vw; line-height: 1vw">Perfect for larger documents, accommodating up to 50 physical pages, including a cover sheet.</span></div>
      </div>`,
      imgSrc: option1,
      icon: envelope,
    },
    {
      id: 2,
      title: "Trackability & Security",
      description: `<div style="padding: 5px 5px; display: inline-block;">Our First-Class Mail services stand out by offering a certain level of trackability through USPS Informed Visibility tracking. Using IMb barcodes, we provide proof of induction into the USPS mail stream and track all subsequent scans throughout the delivery cycle up to the last sortation scan before delivery. While some letters and flat packages may not always show scan events, the majority of First-Class Mail sent on behalf of our clients provides scan events, ensuring your letters and packages are securely handled and placed in USPS's custody.</div>`,
      imgSrc: option2,
      icon: reward,
    },
    {
      id: 3,
      title: "Dynamic Tracking Portal",
      description: `<div style="padding: 0px 5px; display: inline-block; font-weight: 600">Our user-friendly tracking portal allows you to:</div> <br />
      <ul style="display: flex; flex-wrap: wrap; list-style-type: disc; list-style-position: inside;">
        <li style="padding: 0px 15px; font-size: 14px;">Monitor the status of your letters and packages</li>
        <li style="padding: 0px 15px; font-size: 14px;">View letter images</li>
        <li style="padding: 0px 15px; font-size: 14px;">Access key mailing details</li>
      </ul> 
      <div style="padding: 0px 5px; margin-top: 10px; display: inline-block; font-size: 14px;">For clients who need documented proof of mailing, we also offer mailing affidavit services, ensuring that your contents were securely placed in USPS custody.
      </div>`,
      imgSrc: option3,
      icon: monitor,
    },
    {
      id: 4,
      title: "Flexible Mailing Options",
      description: `<div style="padding: 0px 5px; display: inline-block; font-weight: 600">We make sending mail easy, whether it's a single letter or a bulk mailing:</div> <br />
     <ul style="display: flex; flex-wrap: wrap; list-style-type: disc; list-style-position: inside;">
    <li style="padding: 0px 15px; font-size: 14px;"><span style="font-weight: 600">PDF Extraction Tool: Effortlessly extract recipient names and addresses.</li>
    <li style="padding: 0px 15px; font-size: 14px;"><span style="font-weight: 600"> Import from File Method: Import mailing lists directly from your files.</li>
    <li style="padding: 0px 15px; font-size: 14px;"><span style="font-weight: 600">Comprehensive API: Integrate our mailing services with your core platforms.</li>
    </ul>
 
      <div style="padding: 0px 5px; margin-top: 10px; display: inline-block; font-size: 14px;">All mailing event history, letter images, and mailing affidavit images (as applicable) are stored in our application for up to seven years. This information can be downloaded directly from our user interface or accessed through our API for easy integration into your document repositories.
      </div>`,
      imgSrc: option4,
      icon: letter,
    },
  ]

  const toggleBlock = blockId => {
    if (expandedBlock !== blockId) {
      setIsFading(true)
      setTimeout(() => {
        setExpandedBlock(blockId)
        setActiveImage(blocks.find(block => block.id === blockId).imgSrc)
        setIsFading(false)
      }, 500) // This should match the CSS transition duration
    }
  }

  return (
    <>
      <MainLayout location={location} isAbsolute={true}>
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-BD1ZRJNF9F`}
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-BD1ZRJNF9F');
          `}
          </script>
        </Helmet>
        <div className="introSlideCertifiedWrapper">
          <div className="introSlideCertified">
            <div className="slideContent">
              <div className="whatIsCont gutter120">
                  <div className="certifiedsubTitle">
                    First-Class Mail Services
                  </div>
                  <h1>
                  Reliable, Secure, and <br /> Prompt Delivery for <br />{" "}
                  Everyday Mail
                  </h1>
                  <p className="certifiedTitleDesription">
                  At UploadLetters.com, we offer comprehensive First-Class Mail services tailored to meet the diverse needs of individuals, businesses, government agencies, and educational institutions. Whether you're sending a personal letter, business invoices, or important legal documents, our First-Class Mail services provide the reliability, security, and speed you require.
                  </p>
                  <div className="btnHolder getStartedWrapper">
                    <a
                      href="https://app.uploadletters.com/register"
                      className="button intoSlideButton"
                    >
                      <div className="intoSlideButtonText">Get Started</div>
                    </a>
                  </div>
                  <img src={hero} alt="hero"  className="certifiedHero"/>
              </div>
            </div>
          </div>
        </div>

        <div className="whatWeDoSlide" id="what-we-do">
          <div className="slideContent whatWeDoContBG">
            <div className=" whatIsContMain ">
              <div className="whatWeDoTitleBlock">
                <div>
                  <h1 className="whatWeDocertifiedsubTitle">
                  What is First-Class Mail?
                  </h1>
                </div>
                <div className="whatWeDoDescription">
                First-Class Mail is a widely used postal service for sending letters, notices, general correspondence and flats (large envelopes). Commonly referred to as “business mail” or "regular mail," First-Class Mail is known for its relatively inexpensive cost over other types of mail.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="whoUsesCont" style={{ background: "#f2f3fc" }}>
          <h1 className="whatIsTitle">Who Uses First-Class Mail?</h1>
          <div className="whoUsesContBlock">
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Individuals</div>
                <img src={legal} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
              Personal correspondence, and official documents such as tax forms and legal papers.
              </div>
            </div>
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Businesses</div>
                <img src={business} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
              Invoices, bills, contracts, general letters and legal documents. 
              </div>
            </div>
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Government Agencies</div>
                <img src={government} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
              Official notices, sensitive documents, and other critical communications.
              </div>
            </div>
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Educational Institutions</div>
                <img src={individuals} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
              Transcripts, diplomas, admissions letters, and other essential academic documents.
              </div>
            </div>
          </div>
        </div>
        <div className="gutter60">
          <div className="mainSliderContainer paddingRight">
            <div className="splitContainer">
              <div className="optionsContainer">
                <h1 className="typesTitle textLeft">
                Our First-Class Mail Offerings
                </h1>
                {blocks.map(block => (
                  <div
                    key={block.id}
                    className={`optionBlock ${
                      expandedBlock === block.id ? "expanded" : ""
                    }`}
                    onClick={() => toggleBlock(block.id)}
                  >
                    <div className="optionIconBlock">
                      <img src={block.icon} alt="icon" className="optionIcon" />
                    </div>
                    <div>
                      <div className="optionTitleBlock">
                        <div className="optionTitle">{block.title}</div>
                      </div>
                      {expandedBlock === block.id && (
                        <p
                          className="optionContent"
                          dangerouslySetInnerHTML={{
                            __html: block.description,
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={`imageContainer ${
                  isFading ? "fade-out" : "fade-in"
                }`}
              >
                <img src={activeImage} alt="Selected Option" />
              </div>
            </div>
          </div>
        </div>

        <div
          className="mainContainer whatIsCont"
          style={{ background: "#f2f3fc" }}
        >
          <h2 className="whatIsTitle">Why Choose Us?</h2>
          <div className="whyChooseContBlock">
            <div className="advantagesItem">
              <img src={dynamic} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Next Day Service:</span> <br />
                All orders placed by 2:00 PM CST are mailed the very next business day. 
              </div>
            </div>

            <div className="advantagesItem">
              <img src={competetive} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Secure Mailing:</span>{" "}
                <br />
                Trust in our secure handling of sensitive and confidential documents.
              </div>
            </div>
          </div>

          <div className="whyChooseContBlock">
            <div className="advantagesItem">
              <img src={service} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Proven Trackability:</span> <br />
                Benefit from enhanced tracking and proof of mailing with our dynamic tracking portal.
              </div>
            </div>

            <div className="advantagesItem">
              <img src={secure} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">
                Cost-Effective Solutions:
                </span>{" "}
                <br />
                Enjoy competitive pricing, especially for high volumes of First-Class Mail.
              </div>
            </div>
          </div>
        </div>
        <div className="mainContainer gutter60">
          <ABanner />
        </div>
      </MainLayout>
    </>
  )
}

CertifiedMail.defaultProps = {
  location: {},
}

export default CertifiedMail

export const Head = () => (
  <Seo
    title="UploadLetters.com - Certified Mail"
    description="Trust UploadLetters.com for online mailing. Explore Certified, First-Class, and Priority Mail solutions, redefining the mailing experience."
  />
)
